@import '_variables';
.login_container{
    width:300px;
    max-width: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    border: 1px solid $--button-background;
    border-radius: 5px;
    padding: 20px;
    margin-top: 200px;
    background: #f5f5f5;    
    & .heading{
        font-size: 26px;
        color: #000;
        display: block;
        margin-bottom: 30px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
    }
    & .login-field{
        border: 0;        
        display: block;
        width: 97%;
        padding: 10px;
        margin-bottom: 20px;
        padding-right: 0;
        background: lightgray;
        border-radius: 3px;
        font-weight: 700;
        &:focus{
            outline: 0;
        }
    }
    & .small-error{
        color: var(--red);
        font-size: 12px;
        margin-bottom: 20px;
        display: block;
    }
    & .login-btn{
        color: var(--white);
        border: 0;
        background-color: var(--Sage-Green);
        padding: 7px 36px;
        border-radius: 4px;
        font-weight: 700;
        display: block;
        margin: 0 auto;
        margin-top: 20px !important;
        cursor: pointer;
    }
    & .btn-checkbox{
        margin-bottom: 20px;
        font-size: 12px;
        & .checkbox{
            width:12px;
            height: 10px   
        }
        & label{
            color:grey;
        }
    }
}