@import '_variables';
.candyland .VP-view-score{ 
  & .total{
    background: rgba(51, 95, 172, 0.15);
  }
  & .bullet-points {
    & span{
      border:1px solid var(--megenta);
    }
    & span.active{
      background-color: var(--megenta);
      border:1px solid none;
    }
  }
  & .view-score-content h1, & .first-grid-row div{
    color: var(--black);
  }
  & .rankflex{
    & p{
      font-size: 9px;
    }
  }
}
.VP-view-score{
    background-color: $--button-background;
    width: 100vw;
    height: 100vh;
    .view-score-content{        
        padding: 15px 15px 100px 15px;
        background-color: $--button-background;
         & h1 {
            color: var(--Stone);
            text-align: center;            
            //font-family: Proxima Nova;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 2.4px;
            text-transform: uppercase;
            margin-bottom: 36px;
         }
         & .rankflex{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 32px;
            & span {
                text-align: center;
            }
         }
         & .first-grid-row {
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            justify-content: space-between;
            background: var(--Sage-Green);
            color: var(--white);
            padding: 10px 0px;
            border-radius: 5px 5px 0 0;
            & div:nth-child(1){
                //color: var(--Sage-Green);
            }
            & div {
                width: -webkit-fill-available;
                text-align: center;
                text-transform: uppercase;
                font-size: 12px;
            }
         }
         & .data-grid-row {
            display: flex;
            align-items: flex-start;
            align-self: stretch;
            justify-content: space-between;
            //background: var(--Sage-Green);
            color: var(--Charcoal);             
            border-radius: 5px 5px 0 0;
            font-size: 24px;
            & div:nth-child(1){
                font-size: 12px;
                font-weight: 500;
                line-height: 28px;
            }
            & div {
                border: 1px solid #B9B9B9;
                width: -webkit-fill-available;
                text-align: center;
                padding: 10px 0px;
                height: 30px;
                background-repeat: no-repeat !important;
                background-position: center !important;
                background-size: 47px 47px!important;
            }
            & .parElement {
              background-size: 50px 4px !important;
              background-position: bottom !important;
            }
         }
         & .data-grid-row:last-child{
            & div:nth-child(1){                 
                line-height: 16px;
                border-radius: 0 0 0 5px;
            }
            & div:last-child {
                border-radius: 0 0 5px 0;
            }
         }        
    }
    .scroll-container {
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: 100%;
        white-space: nowrap;
        overscroll-behavior-x: contain;
        gap: 20px;
      }
      
      .table-container {
        flex: 0 0 100%;
        scroll-snap-align: start;
        
      }
      .bullet-points {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 53px;
      }
      
      .bullet-points span {
        font-size: 1.5rem;
        margin: 0 5px;
        color: #ccc;
        cursor: pointer;
        border: 1px solid #A9B892;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: var(--white);
      }
      
      .bullet-points span.active {
        //color: #A9B892;
        background-color: var(--Sage-Green);
      }
}