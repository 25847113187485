@import '_variables';
.candyland{ 
    & .VP-view-result{  
        & .view-result-content{
            background: url("../images/Layer_1.svg") !important;
            background-repeat: no-repeat !important;
            background-size: 100% !important;
            background-position: top center !important;
            & h1{
                color: var(--black);
                padding-top: 30px;
            }
            & p{
                color: var(--black);
            }
            & .firstDiv h2{
                color: var(--black) !important;
            }
            & .secondDiv h1{
                padding-top: 0px;
                color: var(--black) !important;
            }
            & .firstDiv img{
                height: auto !important;
            }
        }
    }

}
.VP-view-result{
    width: 100vw;
    height: 100vh;
    background: var(--Sage-Green);
    padding-bottom: 100px;
    & .view-result-content{
        padding: 15px 15px 0 15px;
        background: var(--Sage-Green);
        & h1 {
            color: var(--white);
            text-align: center;            
            //font-family: Proxima Nova;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 2.4px;
            text-transform: uppercase;
            margin-bottom: 34px;
         }
         & p{
            color: var(--white);
            text-align: center;
            /* Lead */
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;  
         }
         & p:nth-child(4){                   
            margin-bottom: 31px;
         }
         & .congo{
            font-weight: bold;
            font-size: 14px;
            display: none;
         }

         & .scoreRow{
            display: flex;
            gap: 16px;
            margin-bottom: 15px;
            
            & .firstDiv{
                border-radius: 10px;
                display: flex;
                flex-grow: 1;
                padding: 0px 18px;
                background: rgba(242, 242, 242, 0.50);
                align-items: center;
                & img {
                    margin-right: 18px;
                    height: 60px;
                }
            }
            & .secondDiv{
                width: 80px;
                height: 80px;                
                border-radius: 10px;
                background: rgba(242, 242, 242, 0.50);
                backdrop-filter: blur(2px);
                & h1 {
                    color: var(--white);
                    text-align: center;
                    /* H2 */
                    // font-family: Proxima Nova;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 48px; /* 112.5% */
                    letter-spacing: 2.4px;
                    text-transform: uppercase;
                }

               
            }

            & h2{
                color: var(--white);
                text-align: center;
                /* H2 */
                //font-family: Proxima Nova;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px; /* 112.5% */
                letter-spacing: 2.4px;
                text-transform: uppercase;
                width: -webkit-fill-available;
                border-left: 1px solid rgba(255, 255, 255, 0.20);
            }
         }
         svg.brand {
            margin-top: 62px !important;
            margin: 0 auto;
            display: block;
        }
        .endBtn{
            color: var(--white);
            text-align: center;
            border: 0;
            /* H3 */
            //font-family: Proxima Nova;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px; /* 112.5% */
            letter-spacing: 1.6px;
            text-transform: uppercase;
            background-color: transparent;
            width: 100%;
            margin-top: 69px;
        }
        & .shareIcon{
            margin-top: 59px !important;
            display: grid;
            margin: 0 auto;
            justify-content: center;
        }
    }

    & .date{
        margin-top: 20px;
        display: block;
        font-size: 18px;
        font-weight: normal;
        display: none;
    }

}
.modalErrorContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    backdrop-filter: blur(5px);

   & .modalError {
        position: fixed;
        top: 5%;
        transform: translateX(-50%);
        left: 50%;
        background: #fff;
        width: 80%;
        border-radius: 5px;
        padding: 20px;
        & h1 {
            color: var(--Sage-Green);
        }
        & a {
            display: block;
            text-align: center;
            text-decoration: none;
            padding-top: 20px;
            padding-bottom: 20px;
            color: var(--Sage-Green);
            font-weight: 600;
        }
    }
}