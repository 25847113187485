@import '../../assets/styles/variables';
.candyland {
    & .endGameModal {
        & .btnColored{
        background: var(--Pink) !important;
        border: 1px solid #000;
        color: var(--black)}
    }
    & .bottomNavigationHolder{
    & span{
        & path{
            fill: var(--black)
        }
        & p, & a{
         color: var(--black)   
        }
    }    }
}
.bottomNavigationHolder{
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: var(--Sage-Green);
    box-shadow: 0px -2px 5px 1px rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-around;
    padding: 10px 0px 20px 0px;
    & span {
        border-right: 1px solid rgba(255, 255, 255, 0.20);
        //padding-right: 15px;
        text-align: center;
        flex: 1;
        & svg{
            height: 33px;
        }

        & p{
            margin-bottom: 0;
            color: rgba(255, 255, 255, 0.50);
            text-align: center;
            /* Body Copy */
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 5px;
        }
        & a{
            margin-bottom: 0;
            color: rgba(255, 255, 255, 0.50);
            text-align: center;
            /* Body Copy */
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: block;
            margin-top: 5px;
            width: 100%;
            text-decoration: none;
        }
    }
    & span:nth-child(5){
        border-right: 0;
        padding-right: 0;
    }

}
.endGameModal_container{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(93, 93, 93, 0.65);
    top: 0;
    
}

.endGameModal{
    width: 332px;
    height: 262px;
    flex-shrink: 0;
    background-color: #fff;
    position: fixed;
    z-index: 99999;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    text-align: center;
    & h3 {
        color: var(--Charcoal);
        text-align: center;
        /* H3 */
        //font-family: Proxima Nova;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; 
        letter-spacing: 1.6px;
        text-transform: uppercase;
        margin-top: 43px;
    }
    & p{
        color: var(--Charcoal);
        text-align: center;
        /* Body Copy */
        //font-family: Proxima Nova;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0px 21px;
    }
    & .btnColored{
        width: 180px;
        height: 45px;
        flex-shrink: 0;
        border-radius: 50px;
        background: var(--Sage-Green);
        backdrop-filter: blur(2px);
        border: 0;
        color: var(--white);
        text-align: center;
        /* H3 */
        //font-family: Proxima Nova;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; 
        letter-spacing: 1.6px;
        text-transform: uppercase;
        margin-top: 17px;
        margin-bottom: 27px;
    }
    & .btnNormal{
        border: 0;
        color: var(--Charcoal);
        text-align: center;
        /* H4 */
        //font-family: Proxima Nova;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 13.5px; /* 112.5% */
        letter-spacing: 0.6px;
        text-transform: uppercase;
        background: transparent;
    }

}