@import '_variables';
.container_order_settings{
    padding: 15px; 
    & thead {
        background: #A9B892;
        color: #fff;
        font-size: 12px;
    }
    & tbody td {
        padding: 0 10px;
        border: 1px solid var(--Sage-Green);
    }
    & tbody td:nth-child(1) {
        & span{
            border: 1px solid var(--Sage-Green);
            color: var(--Sage-Green);
            width: 29px;
            display: block;
            height: 29px;
            line-height: 29px;
            text-align: center;
            border-radius: 29px;
            margin-top: 10px;
            margin-bottom: 10px;
        }        
    }
    
    & th{
        padding: 10px;
        text-align: left;
    }
    & h3{
        color: var(--Sage-Green);
        margin-bottom: 30px;
    }
    & .field-row{
        // display: flex;
        // gap:20px
    }
    & .remove-icons{
        cursor: pointer;
    }
    & .create_entry{
        display: flex;
        width:max-content;
        margin-bottom: 35px;
        background-color: var(--Sage-Green);
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 0px 2px #5d7f68;
        }  


        & button{
            background-color: transparent;
            border:0;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
                      
        }
    }
    & table{
        width: 100%;
    }
    & small{
        font-size: 10px;
    margin-top: -30px;
    display: block;
    font-style: italic;
    }
}