@import '_variables';

.spooky {
   & .view-result-content{
      background: url("../images/spooky-layer.png") !important;
      background-repeat: no-repeat !important;
      background-size: 95% !important;
      background-position: top center !important;
   }
}
.christmas {
   & .view-result-content{
      background: url("../images/christmas-layer.png") !important;
      background-repeat: no-repeat !important;
      background-size: 95% !important;
      background-position: top center !important;
   }
}

.rules_screen{
   padding: 30px 30px 100px 30px;
   .VP_h2_heading {
      display: flex;
      justify-content: center;
      color: var(--Stone);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 2.4px;
      padding-bottom: 15px;
      margin-bottom: 0;
      margin-top: 0;
   }
   .text_block{ 
      margin-top: 20px;
      h4{
         color: var(--Charcoal);         
         font-size: 12px;
         font-style: normal;
         font-weight: 500;
         line-height: 13.5px; 
         letter-spacing: 0.6px;
         text-transform: uppercase;
         margin-bottom: 0;
      }
      p{
         color:  var(--Charcoal);         
         font-size: 12px;
         font-style: normal;
         font-weight: 400;
         line-height: normal;
         margin-top: 8px;
         margin-bottom: 0;
      }
   }
  
   .level{
      display: flex;
      gap: 20px;
      padding-top: 20px;
       
      span{
         width: 80px;
         height: 80px;
         border-radius: 50%;
         line-height: 80px;
         text-align: center;
         color: var(--white);
         font-size: 12px;
         font-style: normal;
         font-weight: 400;
      }
      span:nth-child(1){
         background-color: var(--red);
      }
      span:nth-child(2){
         background-color: var(--blue);
      }
      span:nth-child(3){
         background-color:var(--black);
      }
   }
   .bottom_navigation{
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;      
      background: #fff;
      padding: 20px;
      box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.10);
      svg {
         width: 20px;
         height: 10px;
      }
      a {
         color: var(--Charcoal);
         text-align: center;         
         font-size: 16px;
         font-style: normal;
         font-weight: 500;
         line-height: 18px;
         letter-spacing: 1.6px;
         text-transform: uppercase;
         text-decoration: none;
      }
      .button {
         border-radius: 50px;
         background: var(--Sage-Green);
         backdrop-filter: blur(2px);
         border: 0;
         color: #FFF;
         text-align: center;         
         font-size: 16px;
         font-style: normal;
         font-weight: 500;
         line-height: 41px;
         letter-spacing: 1.6px;
         text-transform: uppercase;
         width: 230px;
         height: 45px;
         &.disabled{            
            opacity: 0.5;
         }
      }
   }
}
.candyland .rules_screen {
   & .bottom_navigation{
     & .button{
      background: var(--Pink);
      border: 1px solid #000;
      color: var(--black)
      }
   }
}