@import '_variables';
.container_rules_settings{
    padding: 15px; 
  
    & .create_entry{
        display: flex;
        width:max-content;
        margin-bottom: 35px;
        background-color: var(--Sage-Green);
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
            box-shadow: 0px 0px 2px #5d7f68;
        }  


        & button{
            background-color: transparent;
            border:0;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
                      
        }
    }
    .code-editor textarea{
        border: 1px solid var(--Sage-Green) !important;
        padding: 15px;
    } 
    & .blocksHolder{
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
        & .blockRow{
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            gap: 20px;
            background: #f1f1f1;
            padding: 15px;
            border-radius: 5px;
            & .mainTitle{
                display: grid;
                grid-template-columns: auto;
                gap: 20px;
                align-items: start;
                align-content: center;
            }
            & .title{
                width: 95%;
                border: 1px solid #cfcfcf;
                padding: 10px;
            }
            & .childTitle{
                height: 25px;
                border: 1px solid #cfcfcf;
            }
            & .childNodes{
                display: grid;
                grid-template-columns: auto;
                gap: 20px;

                & .childNodesRow{
                    display: grid;
                    grid-template-columns: 1fr auto;
                    gap: 20px;
                    align-items: center;
                }
                
            }
        }
    }
    
}