.VP-root{
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.VP-root-logo-holder{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 73px;
  padding-bottom: 36px;
}
.VP-root-logo{
  width: 150px;
}
h1{
  color: #FFF;
  text-align: center;
  /* font-family: Proxima Nova; */
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; 
  letter-spacing: 3.5px;
}
.VP-root-button-holder {
  display: grid;
  align-items: center;
  justify-content: center;
  padding-top: 73px;
  padding-bottom: 36px;
  position: absolute;
  bottom: 0;
  width: 100vw;
  grid-template-columns: 1fr;
  justify-items: center;
}
.candyland{
  & .VP-root h1{
    color: var(--black)
  }
  & .VP-root-button-holder a{
    background: var(--Pink);
    border: 1px solid #000;
    color: var(--black)
  }
}
.VP-root-button-holder a {
    background: #d9d9d99e;
    /* padding: 15px 70px; */
    border-radius: 35px;
    width: 250px;
    height: 50px;
    color: #FFF;
    text-align: center;
    /* font-family: Proxima Nova; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    letter-spacing: 1.6px;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 50px;
}
.home-note-head{
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.home-note-subhead{
  margin-top: 0;
  color: #FFF;
  /* Body Copy */
  //font-family: "Proxima Nova";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 25px;
}