@import '_variables';
.spooky {
    & .players_content{
       background: url("../images/spooky-layer.png") !important;
       background-repeat: no-repeat !important;
       background-size: 95% !important;
       background-position: top center !important;
    }
 }
.christmas {
    & .players_content{
       background: url("../images/christmas-layer.png") !important;
       background-repeat: no-repeat !important;
       background-size: 95% !important;
       background-position: top center !important;
    }
}

.candyland{
    .VP-playes{
        & .players_content{
            background: url("../images/Layer_1.svg") !important;
            background-repeat: no-repeat !important;
            background-size: 100% !important;
            background-position: top center !important;
            & h2, & h4 {
                color: var(--black);
            }
            & p.small, & p.highlighted, & p.normal{
                color: var(--black);
            }             
            & p.highlighted, & p.normal{
                margin-bottom: 0;
                margin-top: 0;
            }
            & .field_box{
                & h4{
                    color: var(--black);
                }
                & p.placeholder{
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 16px;
                }
                & .input_field{
                    color: var(--black);
                    &::placeholder{
                        color: rgba(0, 0, 0, 0.50);
                        text-transform: none;
                        font-size: 16px;
                        letter-spacing: 0.5px;
                    }
                    &::-ms-input-placeholder { /* Edge 12 -18 */
                        color: rgba(0, 0, 0, 0.50);
                        text-transform: none;   
                        font-size: 16px;
                    }                    
                }
            }
            & button{
                background: var(--Pink);
                border: 1px solid #000;
                color: var(--black)
            }
        }
    }
}
.VP-playes{
    background: var(--Sage-Green);
    width: 100vw;
    height: 100vh;
    min-height: 800px;
    
    .players_content{
        padding: 15px;
        h2{
            color: var(--white);
            text-align: center;
            // font-family: Proxima Nova;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 2.4px;
            text-transform: uppercase;
        }
        h4{
            color: var(--white);
            text-align: center;
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 13.5px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            margin-bottom: 54px;
        }
        .field_box{
            background-color: rgba(242, 242, 242, 0.20);
            backdrop-filter: blur(2px);
            border-radius: 10px;
            &:hover{
                background-color: rgba(242, 242, 242, 0.50);
            }
            h4 {
                display: block;
                color: #FFF;
                text-align: center;
                /* H4 */
                // font-family: Proxima Nova;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 13.5px; /* 112.5% */
                letter-spacing: 0.6px;
                text-transform: uppercase;
                padding-top: 9px;
                margin-bottom: 0;
            }
            p.placeholder{
                color: rgba(242, 242, 242, 0.50);
                text-transform: none;
                font-size: 12px;
                letter-spacing: 0.5px;
                font-weight: 600;
                text-align: center;
                padding-bottom: 12px;
                padding-top: 20px;
            }
            .input_field{
                background-color: transparent;
                border: 0;
                outline: 0;
                @supports (-webkit-touch-callout: none) {
                    /* CSS specific to iOS devices */ 
                    width: 94.5%;
                }
                width: 99.5%;
                text-align: center;
                margin-top: 19px;
                height: 30px;
                margin-bottom: 7px;

                color: var(--white);
                text-align: center;
                /* H2 */
                // font-family: Proxima Nova;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 27px; 
                letter-spacing: 2.4px;
                text-transform: uppercase;
                
                &::placeholder{
                    color: rgba(242, 242, 242, 0.50);
                    text-transform: none;
                    font-size: 12px;
                    letter-spacing: 0.5px;
                }
                &::-ms-input-placeholder { /* Edge 12 -18 */
                    color: rgba(242, 242, 242, 0.50);
                    text-transform: none;   
                    font-size: 12px;
                }

            }
        }
        p.small{
            color: var(--white);
            text-align: center;
            /* Small */
            // font-family: Proxima Nova;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        }
        p.highlighted{
            color: var(--white);
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: center;
        }
        p.normal{
            color: var(--white);
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
        }
        button {
            border-radius: 50px;
            background: $--button-background;
            backdrop-filter: blur(2px);
            border: 0;
            color: var(--Sage-Green);
            text-align: center;
            // font-family: Proxima Nova;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 41px;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            width: 230px;
            height: 45px;
            margin: 0 auto;
            display: block;
            margin-top: 30px;
            cursor: pointer;
         }
    }
}