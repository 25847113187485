@import '_variables';
.candyland{
    & .VP-enter-score .enter-score-content{
        & h1, & h2, & h3, & .user_and_score_holder .username h2{
            color: var(--black);
        }
        & .user_and_score_holder .userscore span{
            color: #DF285F;
        }
    }
}
.VP-enter-score{
    background-color: $--button-background;
    width: 100vw;
    height: 100vh;
    .enter-score-content{
        padding: 15px;
        padding-bottom: 130px;
        background-color: $--button-background;
        h1{
            color: var(--Sage-Green);
            text-align: center;
            /* H1 */
            // font-family: Proxima Nova;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: 33px;
            letter-spacing: 3px;
            text-transform: uppercase;
            margin-bottom: 5px;
            @include smallHeightDevice{
                font-size: 20px;
            }
        }
        h2 {
            color: var(--Stone);
            text-align: center;            
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 2.4px;
            text-transform: uppercase;
            @include smallHeightDevice{
                font-size: 22px;
            }
        }
        h3{
            color: var(--Sage-Green);
            text-align: center;            
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 1.6px;
            text-transform: uppercase;
            margin-top: 0;
            margin-bottom: 30px;
            @include smallHeightDevice{
                font-size: 14px;
                margin-bottom: 15px;
            }
        }
        .progress_holder{
            display: flex;
            justify-content: space-between;
            span{
                display: flex;
                align-items: center;
            }
        }
        .user_and_score_holder{
            display: flex;
            gap: 16px;
            margin-bottom: 15px;
            .username{
                height: 80px;
                border-radius: 10px;
                background: var(--white);
                backdrop-filter: blur(2px);
                width: -webkit-fill-available;
                @include smallHeightDevice{
                    height: 60px;
                }
                & h2{
                    color: var(--Charcoal);
                    text-align: center;
                    /* H2 */
                    // font-family: Proxima Nova;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 40px;
                    letter-spacing: 2.4px;
                    text-transform: uppercase;
                    @include smallHeightDevice{
                        line-height: 22px;
                    }
                }
            }
            .userscore{
                min-width: 80px;
                height: 80px;
                border-radius: 10px;
                background: var(--white);
                backdrop-filter: blur(2px);
                @include smallHeightDevice{
                    height: 60px;
                }
                & span {
                    display: block;
                    text-align: center;
                    color: var(--Sage-Green);
                    text-align: center;                    
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 18px; /* 112.5% */
                    letter-spacing: 1.6px;
                    text-transform: uppercase;
                    padding-top: 3px;
                    @include smallHeightDevice{
                        font-size: 12px;
                    }
                }
                & .minus_span{
                    @supports (-webkit-touch-callout: none) {
                        padding-top: 0px;
                        margin-top: -10px;
                    }
                }

                & input{
                    max-width: 94%;
                    border: none;
                    outline: 0;
                    color: var(--Charcoal);
                    text-align: center;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 27px; 
                    letter-spacing: 2.4px;
                    text-transform: uppercase;
                    margin: 0 auto;
                    display: block;
                    padding-top: 8px;
                    @include smallHeightDevice{
                        padding-top: 0px;
                        line-height: 18px;
                        font-size: 16px;
                    }
                }
            }
        }
        .score-navigation{
            margin-top: 25px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid var(--white);
            padding-bottom: 33px !important;
            padding: 15px;
            & a {
                color: var(--Charcoal);
                text-decoration: none;
            }
            & span {
                color: var(--Charcoal);
                text-align: center;
                /* Small */
                // font-family: Proxima Nova;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-top: 5px;
                padding-bottom: 5px;
            }
            & span:nth-child(2){
                font-weight: 700;
                flex-grow: 2;
                border-left: 1px solid var(--Charcoal);
                border-right: 1px solid var(--Charcoal);
            }
            & span:last-child{
                border-right: 1px solid transparent !important;
            }
            & span:nth-child(1){
                padding-right: 20px;
            }
            & span:nth-child(3){
                padding-left: 20px;
            }
            & p {
                color: var(--Charcoal);
                text-align: center;
                /* Large */
                // font-family: Proxima Nova;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
        .bold_text{
            color: var(--Charcoal);
            text-align: center;
            /* Lead */
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding-top: 32px;
        }
        .small_text{
            color: var(--Charcoal);
            /* Body Copy */
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            padding: 0px 40px;
        }
        & .anchorBtn{
            color: var(--white);
            text-align: center;
            // font-family: Proxima Nova;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px; /* 112.5% */
            letter-spacing: 1.6px;
            text-transform: uppercase;
            background-color: var(--Sage-Green);
            border-radius: 36px;
            padding: 13px 17px;
            text-decoration: none;
            display: block;
            width: fit-content;
            margin: 0 auto;
            margin-top: 23px;
        }
    }
    
}