@import '../../assets/styles/variables';
.container{
    width: 100%;    

    & .topBar{
        width: 100%;
        background-color: var(--Sage-Green);
        display: block;
        height: 80px;
        display: flex;
        align-items: center;         
        justify-content: space-between;
        & img{
            height: fit-content;
        }
        & .VP-logout{
            width: 20px;
            padding-right: 15px;
            cursor: pointer;
        }
        & .VP-logo{
            padding-left: 15px;
            width: 200px;
        }
        & label{
            padding-right: 18px;
            font-size: 15px;
            vertical-align: text-top;
        }
    }
    & .sidebar_contianer_holder {
        display: flex;
        height: 90vh;

        & .sidebar{
            flex-grow: 1;
            border-right: 1px solid var(--Charcoal);
            background-color: $--button-background;
            padding: 0 15px;
            & h2{
                color: var(--Sage-Green);
            }
            & a{
                display: block;
                font-size: 15px;
                margin-bottom: 10px;
                color: var(--Charcoal);
                text-decoration: none;
            }
        }

        & .center_content{
            flex-grow: 6;
        }
    }
}