@import '_variables';
.container_home_settings{
    padding: 15px;    
    & h3{
        color: var(--Sage-Green);
        margin-bottom: 30px;
    }

    & .form-label{
        font-size: 15px;
        margin-bottom: 15px;
        display: block;
    }

    & .card_container{
        display: grid;
        grid-template-columns: repeat(4, minmax(250px,300px));
        gap: 24px;

        & .card{
            border: 1px solid var(--Sage-Green);
            padding: 30px;
            border-radius: 15px;
            & .inner-flex{
                display: flex;
                gap: 24px;
            }
            & h2.title{
                margin-top: 0;
                color: var(--Sage-Green);
                margin-bottom: 20px;
                font-size: 18px;
            }
            & img{
                width: 56px;
                fill: #68794e;
            }
            & p{
                font-size: 40px;
                margin-top: 0;
                margin-bottom: 0px;
                font-weight: 500;
                color: #68794e;
            }
        }
    }
}