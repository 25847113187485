@import '../../assets/styles/variables';

.ordermodal {
    background-color: var(--Sage-Green);
    filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.30));
    width: 332px;
    height: 262px;
    flex-shrink: 0;
    position: fixed;
    z-index: 99999;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    & h3 {
        padding-top: 31px;
        color: var(--white);
        text-align: center;
        /* H3 */
        // font-family: Proxima Nova;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 112.5% */
        letter-spacing: 1.6px;
        text-transform: uppercase;
    }
    & p{
        color: var(--white);
        text-align: center;
        /* Body Copy */
        // font-family: Proxima Nova;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 30px;
        padding-right: 30px;
    }
    & .anchorBtn{
        color: var(--Sage-Green);
        text-align: center;
        // font-family: Proxima Nova;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 112.5% */
        letter-spacing: 1.6px;
        text-transform: uppercase;
        background-color: var(--white);
        border-radius: 36px;
        padding: 13px 17px;
        text-decoration: none;
        display: block;
        width: fit-content;
        margin: 0 auto;
        margin-top: 23px;
    }
    & .commonFlex{
        display: flex;
        justify-content: center;
        align-items: center;
        & p.coloredText{
            color: var(--white);
            text-align: center;
            // font-family: Proxima Nova;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 13.5px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            padding-left: 9px;
            padding-right: 0;
        }
    }
}