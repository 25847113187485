:root {
    --Stone: #D7D3CC;
    --Charcoal: #5D5D5D;
    --red: #D12229;
    --blue: #24408E;
    --black: #000;
    --white: #fff;
    --Sage-Green: #A9B892;
    --Top-bar: #A9B892;
}

.spooky{
    --Stone: #D7D3CC;
    --Charcoal: #5D5D5D;
    --red: #D12229;
    --blue: #24408E;
    --black: #000;
    --white: #fff;
    --Sage-Green: #F68A43;
    --Top-bar: #453460;
}

.christmas{
    --Stone: #D7D3CC;
    --Charcoal: #5D5D5D;
    --red: #D12229;
    --blue: #24408E;
    --black: #000;
    --white: #fff;
    --Sage-Green: #066F5B;
    --Top-bar: #066F5B;
}

.candyland{
    --Stone: #D7D3CC;
    --Charcoal: #5D5D5D;
    --red: #D12229;
    --blue: #24408E;
    --black: #000;
    --white: #fff;
    --Sage-Green: #96D1F2;
    --Top-bar: #96D1F2;
    --Pink: #F6AFCE;
    --megenta: #DF285F
}


$--button-background: #F2F2F2;

@mixin smallHeightDevice {    
    @media (max-height: 690px) {
        @content;
    }
}